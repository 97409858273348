<template>
  <v-container>
    <v-skeleton-loader type="table" v-if="!data_loaded"></v-skeleton-loader>

    <Table
      ref="table"
      :headers="headers"
      :items="clients"
      :status="false"
      :delete_meg="'حذف العميل ؟ '"
      :delete_loader="delete_loader"
      :delete_dialog="delete_dialog"
      @delete_row="delete_client($event)"
      @edit_row="edit_row($event)"
      @close_delete="delete_dialog = false"
      @show_delete="delete_dialog = true"
      v-if="data_loaded"
      :id_dialog="id_dialog"
      @show_photo_id="id_dialog = true"
      @close_photo_id="id_dialog = false"
    ></Table>
    <!--table -->

    <BaseForm
      v-if="data_loaded"
      :title="title"
      :form_obj="form_obj"
      ref="emForm"
      :form_error="alerts.error"
      :form_success="alerts.success"
      :success_text="alerts.success_text"
      :error_text="alerts.error_text"
      @save_form="save_form()"
      :save_loading="save_loading"
      @reset_id="(client_id = null), (title = 'اضافة عميل')"
      @reset_rules="reset_rules()"
      @close_snack="close_snack($event)"
    ></BaseForm>
  </v-container>
  <!--/ container -->
</template>

<script>
import Table from "@/components/clients/table";
import BaseForm from "@/components/base/form";

export default {
  name: "expenses",

  data() {
    return {
      id_dialog: false,
      rules_props: {
        phone_exist_prop: false,
        id_number_exist: false,
      },
      delete_dialog: false,
      client_id: null,
      delete_loader: false,
      clients: [],
      title: "اضافة شركة",
      data_loaded: false,
      save_loading: false,
      alerts: {
        success: false,
        error: false,
        success_text: "",
        error_text: "",
      },
      form_obj: {
        client_name: {
          value: "",
          label: "اسم العميل",
          type: "text",
          is_require: true,
          rules: [(v) => !!v || "حقل مطلوب"],
        },

        id_number: {
          value: "",
          label: "رقم الهوية",
          type: "number",
          is_require: true,
          rules: [
            (v) => !this.id_exist || "موجود مسبقا",
            (v) => !!v || "حقل مطلوب",
          ],
        },
        phone: {
          value: "",
          label: "رقم الهاتف",
          type: "number",
          is_require: true,
          rules: [
            (v) => !this.phone_exist || "موجود مسبقا",
            (v) => !!v || "حقل مطلوب",
          ],
        },
        phone_2: {
          value: "",
          label: "رقم الهاتف 2 ",
          type: "number",
        },
        phone_3: {
          value: "",
          label: "رقم الهاتف 3 ",
          type: "number",
        },
        country: {
          value: "",
          label: "البلد",
          type: "text",
        },
        address: {
          value: "",
          label: "العنوان",
          type: "text",
        },

        email: {
          value: "",
          label: "البريد الالكتروني",
          type: "text",
        },
        mail_box: {
          value: "",
          label: " ص.ب",
          type: "text",
        },
        id_img: {
          value: null,
          label: "صورة الهوية",
          type: "file",
        },
      },
      headers: [
        {
          value: "client_name",
          text: "اسم العميل",
          align: "center",
        },
        {
          value: "country",
          text: "البلد",
          align: "center",
        },
        {
          value: "address",
          text: "العنوان",
          align: "center",
        },
        {
          value: "phone",
          text: "رقم الهاتف",
          align: "center",
        },
        {
          value: "phone_2",
          text: "رقم الهاتف ٢",
          align: "center",
        },
        {
          value: "phone_3",
          text: "رقم الهاتف ٣ ",
          align: "center",
        },
        {
          value: "email",
          text: "البريد الالكتروني",
          align: "center",
        },
        {
          value: "id_number",
          text: "رقم الهوية",
          align: "center",
        },
        {
          value: "mail_box",
          text: "ص.ب",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
    };
  },
  computed: {
    phone_exist() {
      return this.rules_props.phone_exist_prop;
    },
    id_exist() {
      return this.rules_props.id_number_exist;
    },
  },
  methods: {
    get_clients() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "clients",
            method: "get",
          },
        })
        .then((res) => {
          this.clients = Object.assign([], res.data.data);
          this.data_loaded = true;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    save_form() {
      this.save_loading = true;
      let formData = new FormData();
      Object.keys(this.form_obj).forEach((e, i) => {
        formData.append(
          e,
          !this.form_obj[e].value ? "" : this.form_obj[e].value
        );
      });

      this.$store
        .dispatch("public__request", {
          config: {
            url: this.client_id ? `update_client/${this.client_id}` : `clients`,
            method: "post",
          },
          data: formData,
        })
        .then((res) => {
          this.alerts.error = false;
          this.save_loading = false;
          this.alerts.success_text = "تم اضافة العميل بنجاح";
          this.alerts.success = true;
          this.$refs.emForm.toggle_btn_form();
          this.get_clients();
        })
        .catch((err) => {
          this.save_loading = false;
          if (err.response.data.data.phone) {
            this.rules_props.phone_exist_prop = true;
          }
          if (err.response.data.data.id_number) {
            this.rules_props.id_number_exist = true;
          }
          this.alerts.error_text = "برجاء مراجعة البيانات";
          this.alerts.error = true;
          this.$refs.emForm.$refs.form.validate();
        });
    },
    reset_rules() {
      Object.keys(this.rules_props).forEach((e) => {
        this.rules_props[e] = false;
      });
    },
    close_snack(ev) {
      if (!ev.prop) {
        this.alerts[ev.type] = false;
      }
    },
    edit_row(ev) {
      this.title = "تعديل العميل";
      Object.keys(ev.item).forEach((e) => {
        if (this.form_obj[e]) {
          this.form_obj[e].value = ev.item[e];
        }
      });
      this.client_id = ev.item.id;
      this.$refs.emForm.fade_form();
    },
    delete_client(type_id) {
      this.delete_dialog = false;
      if (type_id) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `clients/${type_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.alerts.success = true;
            this.alerts.success_text = "تم حذف الشركة";
            this.delete_loader = false;
            this.get_clients();
            this.delete_dialog = false;

            if (this.$refs.emForm.toggle_form == true) {
              this.$refs.emForm.toggle_btn_form();
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    },
  },
  created() {
    this.get_clients();
  },
  components: {
    Table,
    BaseForm,
  },
};
</script>
