<template>
  <div>
    <v-skeleton-loader
      v
      type="table"
      class="mt-10"
      v-if="tbody.length != 0"
    ></v-skeleton-loader>
    <v-card v-if="tbody.length == 0" class="mt-10">
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items-per-page="10"
        :items="items"
        :search="search"
        :no-data-text="'لا يوجد بيانات'"
        :no-results-text="'لايوجد بيانات'"
        loading-text="جارى التحميل"
        :footer-props="this.$store.state.dataTableFooterProps"
      >
        <template slot="body.prepend">
          <tr>
            <td :colspan="headers.length">
              <v-text-field
                class="pa-0 ma-0 mb-4"
                hide-
                label="بحث"
                v-model="search"
                append-icon="search"
                full-width
              ></v-text-field>
            </td>
          </tr>
        </template>

        <template v-slot:item="{ item }">
          <tr class="text-center">
            <td
              v-for="(td, x) in headers.filter(
                (ev) => ev.value != 'status' && ev.value != 'actions'
              )"
              :key="x"
            >
              {{
                td.sec_val
                  ? item[td.value][td.sec_val]
                  : item[td.value]
                  ? item[td.value]
                  : "_"
              }}
            </td>

            <td v-if="status">
              <v-chip
                :color="item.status == 1 ? 'success' : 'error'"
                class="px-2"
                x-small
              >
                {{ item.status == 1 ? " فعال" : " غير فعال" }}
              </v-chip>
            </td>

            <td>
              <v-btn
                v-if="item.id_photo"
                @click="(id_photo = item.id_photo), $emit('show_photo_id')"
                icon
                small
              >
                <v-icon small>visibility</v-icon>
              </v-btn>
              <v-btn @click="$emit('edit_row', { item: item })" icon small>
                <v-icon small>edit</v-icon>
              </v-btn>
              <v-btn icon small @click="get_delete_id(item.id)">
                <v-icon small>delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

        <!--/ actions -->
      </v-data-table>
      <!--/ v-card (table )-->
    </v-card>
    <!--/ table -->

    <v-dialog :value="id_dialog" max-width="500">
      <v-card>
        <v-card-text class="pt-5 text-center">
          <img width="100%" :src="`${$store.state.def_path}/${id_photo}`" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="$emit('close_photo_id')" color="primary" depressed
            >تم</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="300" persistent :value="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block">{{
          delete_meg
        }}</v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="$emit('delete_row', delete_id)"
            depressed
            color="error"
            >حذف</v-btn
          >
          <v-btn @click="$emit('close_delete')" depressed>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </div>
</template>

<script>
export default {
  name: "BaseTable",
  props: [
    "headers",
    "items",
    "status",
    "delete_meg",
    "delete_loader",
    "delete_dialog",
    "id_dialog",
  ],
  data() {
    return {
      id_photo: "",
      search: "",
      delete_id: null,
      users: [],
    };
  },
  computed: {
    tbody() {
      return this.users || [];
    },
  },
  methods: {
    get_users() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "users",
            method: "get",
          },
        })
        .then((res) => {
          this.users = Object.assign([], res.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    edit_employee(item, ind) {
      this.$emit("edit_employee", { item: item, index: ind });
    },
    get_delete_id(id) {
      this.delete_id = id;
      this.$emit("show_delete");
    },
    toggle_status(ev) {
      console.log(ev);
      this.$store
        .dispatch("public__request", {
          config: {
            url: `toggle?user=${ev.id}&status=${ev.status ? 1 : 0}`,
            method: "post",
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
  created() {
    let vm = this;
    window.onkeyup = function (e) {
      // make delete action in enter key
      if (e.key == "Enter" && vm.delete_id && !vm.delete_loader) {
        vm.delete_employee();
      }
    };
  },
};
</script>
